import React, {useState} from "react";
import {Link} from "gatsby";
import {Container, Row, Col} from "react-bootstrap";

import {
    Section,
    Button,
    Title,
    Text,
    Box
} from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Lightbox from 'react-image-lightbox';
import Masonry from "react-masonry-component";

const WorkSingle = (props) => {

    const masonryOptions = {
        transitionDuration: 1000
    }

    const [photoIndex, setPhotoIndex] = useState(0)
    const [isOpen, setIsOpen] = useState(false)
    const [images] = useState(props.works?.data.attributes.image.data)
    return (
        <>
            <PageWrapper>
                <Container fluid className="px-sm-5"
                    css={`
            margin-top: 92px;
          `}>
                    <Title variant="secSm" className="my-4">
                        {
                        props?.works?.data.attributes.client
                    } </Title>
                    <Text variant="tag">
                        {
                        props?.works?.data.attributes.description
                    }</Text>
                </Container>
                {/* <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  To satisfy our goal of creating a brand that could adapt
                  across many use cases, we took the base form of their new logo
                  and used it as an artboard. As long as the structural outline
                  of the mark stayed consistent their brand was allowed to flex
                  and adapt to specific uses. And by introducing these
                  illustrative elements to the logomark, Strictly found it’s
                  voice.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section> */}
                {/* <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  Shopper
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Time</Text>
                <Title variant="cardBig" className="mt-3">
                  Apr 14, 2020
                </Title>
              </Col>
              <Col lg="4">
                <Button arrowRight>Live work</Button>
              </Col>
            </Row>
          </Container>
        </div> */}
                <Section className="mt-lg-5">
                    <Container fluid>
                        <Masonry options={masonryOptions}
                            className={"masonry-grid row"}
                            // default ''
                        >
                            <Row> {/* {props.works.data.attributes.image.data.map(image => (
                <Col xs="12" className="mb-5">
                  <img src={"http://localhost:1337" + image.attributes.formats.medium.url} alt="" className="img-fluid w-100" />
                </Col>
              ))} */}

                                {
                                images.map((image, index) => (
                                    <Col lg="3" md="4" sm="6"
                                        key={index}
                                        className="filtr-item">
                                        <img src={
                                                "http://localhost:1337" + image.attributes.formats.large.url
                                            }
                                            alt=""
                                            className="img-fluid w-100"
                                            onClick={
                                                () => {
                                                    setPhotoIndex(index)
                                                    setIsOpen(true)
                                                }
                                            }/> {
                                        isOpen && (
                                            <Lightbox mainSrc={
                                                "http://localhost:1337" + images[photoIndex].attributes.formats.large.url
                                                }
                                                nextSrc={
                                                  "http://localhost:1337" + images[(photoIndex + 1) % images.length].attributes.formats.large.url
                                                }
                                                prevSrc={
                                                    "http://localhost:1337" + images[(photoIndex + images.length - 1) % images.length].attributes.formats.large.url
                                                }
                                                onCloseRequest={
                                                    () => setIsOpen(false)
                                                }
                                                onMovePrevRequest={
                                                    () => setPhotoIndex((photoIndex + images.length - 1) % images.length)
                                                }
                                                onMoveNextRequest={
                                                    () => setPhotoIndex((photoIndex + 1) % images.length)
                                                }
                                                enableZoom={false}
                                                />
                                        )
                                    } </Col>
                                ))
                            } </Row>
                        </Masonry>
                    </Container>
                </Section>
                {/* <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4">
              Key Findings
            </Title>
            <Row>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  01. Basics
                </Title>
                <Text variant="p">
                  To satisfy our goal of creating a brand that could adapt
                  across many use cases, we took the base form of their new logo
                  and used it as an artboard. As long as the structural outline
                  of the mark stayed consistent their brand was allowed to flex
                  and adapt to specific uses.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  02. Goals
                </Title>
                <Text variant="p">
                  To satisfy our goal of creating a brand that could adapt
                  across many use cases, we took the base form of their new logo
                  and used it as an artboard.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  03. Problems
                </Title>
                <Text variant="p">
                  To satisfy our goal of creating a brand that could adapt
                  across many use cases, we took the base form of their new logo
                  and used it as an artboard. As long as the structural outline
                  of the mark stayed consistent their brand was allowed to flex
                  and adapt to specific uses.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  04. Solutions
                </Title>
                <Text variant="p">
                  To satisfy our goal of creating a brand that could adapt
                  across many use cases, we took the base form of their new logo
                  and used it as an artboard. As long as the structural outline
                  of the mark stayed consistent their brand was allowed to flex
                  and adapt to specific uses.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section mt="22%" bg="dark">
          <Container>
            <Row
              css={`
                margin-top: -40%;
              `}
            >
              <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>
              <Col xs="12" className="mb-5">
                <img src={imgS6} alt="" className="img-fluid w-100" />
              </Col>
            </Row>

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="portfolio-details">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Replace
                </Button>
              </Link>
            </div>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box> */} </PageWrapper>
        </>
    );
};
export default WorkSingle;
